<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="500px"
  >
    <div class="iseq-variant-reclassification-modal">
      <div class="iseq-variant-reclassification-modal__header">
        <span class="iseq-variant-reclassification-modal__header__title">Reclassify variant</span>
      </div>

      <div class="iseq-variant-reclassification-modal__message">
        <b>Reclassifying variant with parameters:</b><br>
        Gene: {{ variant.gene }},<br>
        Variant: {{ variant.variant }},<br>
        Tumor type: {{ variant.tumorType }}.<br>
        Tumor subtype: {{ variant.tumorSubtype }}.<br>
        Auto-assigned AMP/ASCO/CAP classification: <b>{{ variant.primaryClassification }}</b>.
        <template v-if="variant.manualClassification">
          <br>
          Manual classification: <b>{{ variant.manualClassification }}</b><br>
          Comment: {{ variant.comment }}
        </template>
      </div>

      <div class="iseq-variant-reclassification-modal__inputs">
        <v-select
          v-model="newClassification"
          :items="classifications[type]"
          item-text="name"
          item-value="id"
          outlined
          hide-details
          style="margin-bottom: 5px"
          label="New classification"
        />

        <v-text-field
          v-model="comment"
          outlined
          max-length="20"
          counter="1000"
          label="Comment"
        />
      </div>

      <div class="iseq-variant-reclassification-modal__buttons">
        <v-iseq-btn
          type="secondary"
          @click="cancelReclassification()"
        >
          Cancel
        </v-iseq-btn>

        <v-iseq-btn
          type="primary"
          :disabled="!newClassification || comment.length > 1000"
          @click="confirmReclassification()"
        >
          Reclassify
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqVariantReclassificationModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      variant: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        validator: function(value) {
          const allowedValues = ['GERMLINE', 'SOMATIC'];
          return allowedValues.includes(value);
        },
        required: true
      }
    },
    data: function () {
      return {
        isOpen: true,
        newClassification: undefined,
        comment: "",
        classifications: {
          GERMLINE: [
            {id: "BENIGN", name: "Benign"},
            {id: "LIKELY_BENIGN", name: "Likely Benign"},
            {id: "VUS", name: "VUS"},
            {id: "LIKELY_PATHOGENIC", name: "Likely Pathogenic"},
            {id: "PATHOGENIC", name: "Pathogenic"}
          ],
          SOMATIC: [
            {id: "TIER_IA", name: "Tier Ia"},
            {id: "TIER_IB", name: "Tier Ib"},
            {id: "TIER_IIC", name: "Tier IIc"},
            {id: "TIER_IID", name: "Tier IId"},
            {id: "TIER_III", name: "Tier III"},
            {id: "TIER_IV", name: "Tier IV"}
          ]
        }
      }
    },
    created() {
      this.comment = this.variant.comment;
      this.newClassification = this.variant.manualClassification ? this.variant.manualClassification : this.variant.primaryClassification;
    },
    methods: {
      cancelReclassification() {
        this.$emit('actionRejected');
      },
      confirmReclassification() {
        this.$emit('actionConfirmed', {
          gene: this.variant.gene,
          variant: this.variant.variant,
          hgsv: "ABCD",
          tumorType: this.variant.tumorType,
          tumorSubtype: this.variant.tumorSubtype,
          primaryClassification: this.variant.primaryClassification,
          manualClassification: this.newClassification,
          comment: this.comment
        });
      },
      classificationName(id) {
        const classificationsList = this.classifications[this.type];
        return classificationsList.find(n => n.id === id).name;
      }
    }
  }
</script>

<style scoped lang="scss">
.iseq-variant-reclassification-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 500;
    }
  }

  &__message {
    margin-bottom: 20px;
    width: 100%;
    color: var(--dark);
    font-size: 1.4em;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .iseq-btn {
      margin-left: 4px;
    }
  }
}
</style>