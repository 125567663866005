import Vue from 'vue'
import VueCookies from 'vue-cookies'
import request from '@/customjs/request.js'
import store from '@/store'
import router from '@/router'
import dayjs from "dayjs";
import {generatePagingData, pagingDataParse, parseFiles, timeZoned} from "../../plugins/utils";
import {permissionCheck} from "../../plugins/permissions";
import {filenameCollisionOnUploadError, handleDeletedResourceError} from "../../helpers/errorHandlers";

export default {
  namespaced: true,
  state: {
    newSampleInfo: {
      name: '',
      description: '',
    },
    sample: {
      properties: {},
      filesTree: {},
      analysesNames: [],
      runningAnalyses: [],
      details: {
        project: {},
        status: {}
      }
    },
    samplesMinimal: [],
    samplesTableOptions: generatePagingData({raw: true}),
    totalElements: 0,
    content: [],
    analysesContent: [],
    sampleVariantAnalyses: [],
    analysesTotalElements: 0,
  },
  mutations: {
    setNewSampleInfo(state, payload) {
      state.newSampleInfo = {...payload}
    },
    setSamplesTableOptions (state, payload) {
      state.samplesTableOptions = payload;
    },
    clearSamplesTableOptions(state) {
      state.samplesTableOptions = generatePagingData({raw: true})
    },
    setSamplesAllTableOptions (state, payload) {
      state.samplesTableOptions.itemsPerPage =  payload;
    },
    setSampleVariantAnalyses(state, payload) {
      state.sampleVariantAnalyses = payload;
    },
    setContent(state, payload) {
      payload.map(sample => {
        sample.created = timeZoned(sample.created);
        sample.validUntil = timeZoned(sample.validUntil);
        return sample;
      });
      state.content = payload
    },
    clearContent(state) {
      state.content = [];
      state.totalElements = 0;
    },
    setSampleListMinimal(state, payload) {
      state.samplesMinimal = payload;
    },
    setTotalElements(state, payload) {
      state.totalElements = payload
    },
    setTotalElementsAnalyses(state, payload) {
      state.analysesTotalElements = payload
    },
    setContentAnalyses(state, payload) {
      payload.map(analysis => {
        analysis.started = timeZoned(analysis.started);
        analysis.finished = timeZoned(analysis.finished);
        return analysis
      });
      state.analysesContent = payload;
    },
    setProperties(state, data) {
      state.sample.properties = data
    },
    setFilesTree(state, data) {
      data = parseFiles([data]);
      state.sample.size = data[0].size;
      state.sample.filesTree = data[0];
    },
    setSample(state, data) {
      data.originalValidityDate = data.validityDate;
      data.creationDate = timeZoned(data.creationDate);
      data.validityDate = timeZoned(data.validityDate);
      state.sample.details = data;
    },
    setRunningAnalyses(state, data) {
      state.sample.runningAnalyses = data;
    },
    setSampleAnalysesNames(state, data) {
      state.sample.analysesNames = data
    },
    setSampleSize(state, data) {
      let names = ["B", "KB", "MB", "GB"];
      let i=0;
      let subValue="00";
      while (data.size>1023 && i<3){
        subValue = (parseInt(((data.size/10.24)%100).toString(), 10)).toString();
        i++;
        data.size=parseInt((data.size/1024).toString(), 10)
      }
      if (subValue.length<2) subValue = "0" + subValue;
      state.sample.size= data.size + '.' + subValue + names[i];
    },
    cleanSample(state){
      state.sample = {properties: {}, filesTree:{}, analysesNames: [], runningAnalyses: [], details:{project: {}, status:{}}};
    },
    cleanAllData(state){
      let sample = {properties: {}, filesTree:{}, analysesNames: [], runningAnalyses:[], details:{project: {}, status:{}}};
      state = {
        sample: sample,
        samples: [],
        samplesMinimal: [],
        samplesTableOptions: generatePagingData({raw: true}),
        totalElements: 0,
        content: [],
        analysesContent: [],
        analysesTotalElements: 0,
      }
    },
    resetContent(state){
      state.content = [];
    },
    resetContentAnalyses(state){
      state.analysesContent = [];
    }
  },
  actions: {

    // GET
    getSampleList({commit, state}) {
      let projectUuid = store.state.project.project.uuid;
      const pagingParams = pagingDataParse({
        sortDesc: state.samplesTableOptions.sortDesc,
        sortBy: state.samplesTableOptions.sortBy,
        itemsPerPage: state.samplesTableOptions.itemsPerPage,
        totalElements: state.totalElements,
        page: state.samplesTableOptions.page
      });

      request.request({
        endpoint: `projects/${projectUuid}/samples?${pagingParams}`,
        callback: function (data) {
          commit('setContent', data.content);
          commit('setTotalElements', data.totalElements);
        }
      })
    },

    getSampleListMinimal({commit}) {
      let projectUuid = store.state.project.project.uuid;

      request.request({
        endpoint: `projects/${projectUuid}/samples/minimal`,
        callback: function (data) {
          commit('setSampleListMinimal', data)
        }
      })
    },

    getSample({commit, dispatch, rootState}, uuid) {
      commit('cleanSample');
      Vue.$log.debug("vue.vuex.sample.getSample");
      request.request({
        endpoint: "samples/" + uuid,
        callback: function (data) {
          if (Date.now() < dayjs(data.validityDate).valueOf() && data.status.id !== "DELETED") {
            commit('setSample', data);

            // When sample is valid checking if context is correct
            if (rootState.organization.organization.uuid !== data.project.organizationUuid) {
              store.dispatch("organization/getHeaderOrganizations", {
                preferredOrganization: data.project.organizationUuid,
                preferredProject: data.project.uuid
              });
            } else if (rootState.project.project.uuid !== data.project.uuid) {
              store.dispatch("project/getProject", {uuid: data.project.uuid});
            }

            dispatch('getSampleAnalysesNames', uuid);
            dispatch('getRunningAnalyses', {uuid: uuid});
            store.dispatch('analysis/getSampleAnalyses', uuid);
            if (permissionCheck('sampleFilesView')) {
              dispatch('getFilesTree', {sampleUuid: uuid});
            }
            commit('setNewSampleInfo', {name: data.name, description: data.description})
          } else {
            if (Date.now() >= dayjs(data.validityDate).valueOf()){
              handleDeletedResourceError("SAMPLE_OUTDATED");
            } else {
              handleDeletedResourceError();
            }
          }
        }
      });
    },

    getSampleVariantAnalyses({commit}, payload) {
      request.request({
        endpoint: "samples/" + payload.uuid + "/analyses/variant",
        callback: function (data) {
          commit('setSampleVariantAnalyses', data)
        }
      });
    },

    getSampleAnalysesNames({commit}, uuid) {
      Vue.$log.debug("vue.vuex.sample.getSampleAnalysesNames");
      request.request({
        endpoint: "samples/" + uuid + "/analyses/minimal",
        callback: function (data) {
          commit('setSampleAnalysesNames', data.map(node => node.name))
        }
      });
    },

    getRunningAnalyses({commit}, payload) {
      request.request({
        endpoint: `samples/${payload.uuid}/analyses/running`,
        callback: function(data) {
          commit('setRunningAnalyses', data);
        }
      });
    },

    getFilesTree({state, commit}, payload) {
      request.request({
        endpoint: `samples/${payload.sampleUuid}/tree`,
        callback: function (data) {
          if (data.id === state.sample.details.uuid) {
            commit("setFilesTree", data)
          }
        }
      })
    },

    // ADD

    addProperty({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.sample.addProperty");
      request.request({
        endpoint: `samples/${payload.uuid}/properties`,
        data: payload.data,
        method: "post",
        callback: function (data) {
          dispatch('getSample', payload.uuid)
        }
      })
    },

    overwriteProperty({dispatch}, payload) {
      request.request({
        endpoint: `samples/${payload.uuid}/properties/${payload.data[0].key}`,
        data: payload.data,
        method: "put",
        callback: function (data) {
          dispatch('getSample', payload.uuid)
        }
      })
    },

    addFile({state, dispatch}, payload) {
      store.dispatch("snackbarQueue/addConfirmationMessage", {type: "fileUpload"});
      request.request({
        service: "API_FILES",
        method: "post",
        endpoint: `samples/${payload.uuid}/file/disk`,
        data: payload.formData,
        additionalData: payload.uuid,
        headers: {
          'Authorization': 'Bearer ' + store.state.security.accessToken,
          'Content-Type': 'multipart/form-data',
          'File-Size-Sum': payload.fileSizeSum
        },
        callback: function (data) {
          dispatch('getFilesTree', {sampleUuid: payload.uuid})
        },
        errorCallback: function (error) {
          if (error.response.data.message === "FILE_UPLOAD_ALREADY_IN_PROGRESS") {
            filenameCollisionOnUploadError();
          } else {
            store.dispatch('snackbarQueue/addMessage', {
              message: error.response.data.message,
              type: "error"
            });
          }
        }
      })
    },

    uploadFileFromFtp({dispatch}, payload) {
      request.request({
        service: "API_FILES",
        method: "post",
        endpoint: `samples/${payload.uuid}/file/ftp`,
        data: {
          filename: payload.ftp.filename,
          password: payload.ftp.password,
          url: payload.ftp.url,
          username: payload.ftp.username
        },
        callback: function() {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "fileUpload"});
        },
        errorCallback: function (error) {
          if (error.response.data.message === "FILE_UPLOAD_ALREADY_IN_PROGRESS") {
            filenameCollisionOnUploadError();
          } else {
            store.dispatch('snackbarQueue/addMessage', {
              message: error.response.data.message,
              type: "error"
            });
          }
        }
      })
    },

    addFileFromURL({}, payload) {
      let data = {
        filename: payload.name,
        url: payload.url,
        username: payload.username || null,
        password: payload.password || null
      };

      request.request({
        service: "API_FILES",
        method: "post",
        endpoint: `samples/${payload.uuid}/file/http`,
        headers: {
          'Authorization': 'Bearer ' + store.state.security.accessToken,
        },
        data: data,
        callback: function() {
          store.dispatch("snackbarQueue/addConfirmationMessage", {type: "fileUpload"});
        },
        errorCallback: function (error) {
          if (error.response.data.message === "FILE_UPLOAD_ALREADY_IN_PROGRESS") {
            filenameCollisionOnUploadError();
          } else {
            store.dispatch('snackbarQueue/addMessage', {
              message: error.response.data.message,
              type: "error"
            });
          }
        }
      })
    },

    // DELETE

    deleteSample({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.sample.deleteSample");
      request.request({
        snackbarMessage: "Deleting sample",
        endpoint: `samples/${payload.uuid}`,
        method: "delete",
        callback: function () {
        }
      })
    },

    updateSample({dispatch}, payload) {
      request.request({
        endpoint: `samples/${payload.uuid}`,
        method: "patch",
        data: {name: payload.name, description: payload.description},
        callback: function () {
          dispatch('getSample', payload.uuid);
        }
      })
    },

    deleteProperty({dispatch}, payload) {
      Vue.$log.debug("vue.vuex.sample.deleteProperty");
      request.request({
        snackbarMessage: "Deleting property from sample",
        endpoint: `samples/${payload.uuid}/properties/${payload.key}`,
        method: "delete",
        callback: function () {
          dispatch('getSample', payload.uuid);
        }
      })
    },

    deleteFile({dispatch}, payload) {
      let endpoint;
      switch (payload.type) {
      case 'sample':
        endpoint = `samples/${payload.sampleUuid}/file`;
        break;
      case 'analysis':
        endpoint = `analyses/${payload.analysisUuid}/file`;
        break;
      }

      Vue.$log.debug("vue.vuex.sample.deleteFile");
      request.request({
        snackbarMessage: "Deleting file from sample",
        endpoint: endpoint,
        data: {filename: payload.key},
        method: "delete",
        callback: function () {
          dispatch('getFilesTree', {sampleUuid: payload.sampleUuid})
        }
      })
    },

    downloadFile({}, payload) {
      let endpoint;

      switch (payload.type) {
      case 'sample':
        endpoint = `samples/${payload.sampleUuid}/download-url`;
        break;
      case 'analysis':
        endpoint = `analyses/${payload.analysisUuid}/download-url`;
        break;
      case 'analysisDemo':
        endpoint = `demo/analyses/${payload.analysisUuid}/download-url`;
      }

      Vue.$log.debug("vue.vuex.sample.downloadFile");
      return new Promise((response) => {
        request.request({
          endpoint: endpoint,
          data: {filename: payload.key},
          method: "post",
          callback: function (data) {
            response(data)
          }
        })
      })
    },

    // CREATE

    createSample({dispatch}, payload) {
      request.request({
        endpoint: `projects/${payload.uuid}/samples`,
        method: "post",
        data: payload.data,
        callback: function (data) {
          const props = {
            uuid: data.uuid,
            files: payload.files,
            urls: payload.urls,
            ftp: payload.ftp
          };
          dispatch("uploadFilesToNewSample", props);
          router.push({path: `/samples/${data.uuid}`});
        }
      })
    },

    uploadFilesToNewSample({dispatch}, payload) {
      if (Object.keys(payload.files).length) {
        let parameters = '?';
        let formData = new FormData();
        let fileSizeSum = 0;
        let fileNameFixRegex= /[^\w|\-|\.]+/gi
        for (let i = 0; i < Object.keys(payload.files).length; i++) {
          let key = Object.keys(payload.files)[i];
          let originalFile = payload.files[key];

          // Modify the file name (remove invalid characters)
          let newFileName = originalFile.name.replace(fileNameFixRegex, "");

          // Create a new file object with the modified name, and other properties from the original file
          let newFile = new File([originalFile], newFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
          });

          formData.append('file' + i + 'size', newFile.size);
          formData.append('file'+ i + 'lastModified', newFile.lastModified);
          formData.append('file' + i, newFile);
          fileSizeSum += newFile.size;
        }
        dispatch('addFile', {
          fileSizeSum: fileSizeSum,
          uuid: payload.uuid,
          formData: formData,
          parameters: parameters
        });
      }

      if (Object.keys(payload.urls).length) {
        for (let node in payload.urls) {
          dispatch('addFileFromURL', {
            uuid: payload.uuid,
            url: payload.urls[node].url,
            name: payload.urls[node].name,
            username: payload.urls[node].username,
            password: payload.urls[node].password
          });
        }
      }

      if(payload.ftp.url !== '') {
        dispatch("uploadFileFromFtp", payload);
      }
    },

    extendValidity ({dispatch}, payload) {
      request.request({
        endpoint: `samples/${payload.uuid}/extend`,
        method: "patch",
        data: {validity: payload.time},
        callback: function () {
          dispatch('getSample', payload.uuid)
        }
      })
    },

    getAnalysisBySample({commit}, payload) {
      const pagingParams = payload.data ? payload.data : generatePagingData({})

      request.request({
        endpoint: `samples/${payload.uuid}/analyses/tab?${pagingParams}`,
        callback: function (data) {
          commit("setContentAnalyses", data.content);
          commit("setTotalElementsAnalyses", data.totalElements);
        }
      })
    },
  },
  getters: {
    samplesTableOptions: (state) => {
      return state.samplesTableOptions;
    }
  }
}
