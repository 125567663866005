<template>
  <div>
    <v-dialog
      v-model="model"
      persistent
      max-width="600px"
    >
      <div
        class="iseq-report-variants-filtering"
      >
        <div class="iseq-report-variants-filtering__header">
          <span class="iseq-report-variants-filtering__header__title">
            Filter by reclassification status
          </span>
        </div>

        <v-select
          v-model="minerFilteringMode"
          outlined
          hide-details
          :items="minerHeaders"
          item-text="text"
          item-value="value"
          label="Variant MINER filter"
        />

        <div class="iseq-report-variants-filtering__separator" />

        <div class="iseq-report-variants-filtering__header">
          <span class="iseq-report-variants-filtering__header__title">
            Filter by column values
          </span>
        </div>

        <v-select
          v-model="selectedHeaderFilter"
          outlined
          hide-details
          :items="headers"
          item-text="name"
          return-object
          label="Column to filter"
        />

        <div
          v-if="filteredHeaderValid"
        >
          <div
            class="iseq-report-variants-filtering__filter"
          >
            <v-select
              v-model="filterType"
              :items="filterOptions[selectedHeaderFilter.type ? selectedHeaderFilter.type : 'string']"
              outlined
              hide-details
              label="Filter type"
              class="iseq-report-variants-filtering__filter__field"
            />

            <template v-if="filterType !== undefined">
              <template v-if="!selectedHeaderFilter.type || selectedHeaderFilter.type === 'string' || selectedHeaderFilter.type === 'number'">
                <v-text-field
                  v-show="filterType !== 'Is empty' && filterType !== 'Is not empty'"
                  v-model="filterValue"
                  name="name"
                  outlined
                  hide-details
                  label="Value to filter"
                  :type="selectedHeaderFilter.type === 'number' ? 'number' : 'text'"
                  class="iseq-report-variants-filtering__filter__field"
                />
              </template>

              <template v-else-if="selectedHeaderFilter.type === 'boolean' || selectedHeaderFilter.type === 'select'">
                <v-select
                  v-if="filterType !== 'Is empty' && filterType !== 'Is not empty'"
                  v-model="filterValue"
                  :items="selectedHeaderFilter.type === 'boolean' ? ['true', 'false'] : selectFilterOptions(selectedHeaderFilter.value)"
                  :multiple="selectedHeaderFilter.type === 'select'"
                  outlined
                  hide-details
                  label="Value to filter"
                  class="iseq-report-variants-filtering__filter__field"
                >
                  <template
                    v-if="selectedHeaderFilter.display && selectedHeaderFilter.display === 'icon'"
                    #selection="{ item }"
                  >
                    <template v-if="selectedHeaderFilter.icons[item]">
                      <v-icon
                        color="text"
                        class="mr-1"
                      >
                        {{ selectedHeaderFilter.icons[item] }}
                      </v-icon>
                    </template>

                    <template v-else>
                      <span class="mr-1">
                        {{ item }}
                      </span>
                    </template>
                  </template>

                  <template
                    v-else-if="selectedHeaderFilter.display && selectedHeaderFilter.display === 'pill'"
                    #selection="{ item }"
                  >
                    <span
                      class="pill"
                      :style="{'background-color': selectedHeaderFilter.colors[item] ? selectedHeaderFilter.colors[item] : 'black'}"
                    >
                      {{ selectedHeaderFilter.icons[item] ? selectedHeaderFilter.icons[item] : item }}
                    </span>
                  </template>

                  <template
                    v-if="selectedHeaderFilter.display && selectedHeaderFilter.display === 'icon'"
                    #item="{ item }"
                  >
                    <v-checkbox
                      :value="filterValue ? filterValue.includes(item) : false"
                      :checked="filterValue ? filterValue.includes(item) : false"
                      style="pointer-events: none"
                      color="primary"
                      class="mr-3"
                    />
                    
                    <template v-if="selectedHeaderFilter.icons[item]">
                      <v-icon color="text">
                        {{ selectedHeaderFilter.icons[item] }}
                      </v-icon>
                    </template>

                    <template v-else>
                      {{ item }}
                    </template>
                  </template>

                  <template
                    v-else-if="selectedHeaderFilter.display && selectedHeaderFilter.display === 'pill'"
                    #item="{ item }"
                    :style="{'background-color': selectedHeaderFilter.colors[item] ? selectedHeaderFilter.colors[item] : 'black'}"
                  >
                    <v-checkbox
                      :value="filterValue ? filterValue.includes(item) : false"
                      :checked="filterValue ? filterValue.includes(item) : false"
                      style="pointer-events: none"
                      color="primary"
                      class="mr-3"
                    />

                    <span
                      class="pill"
                      :style="{'background-color': selectedHeaderFilter.colors[item] ? selectedHeaderFilter.colors[item] : 'black'}"
                    >
                      {{ selectedHeaderFilter.icons[item] ? selectedHeaderFilter.icons[item] : item }}
                    </span>
                  </template>
                </v-select>
              </template>
            </template>
          </div>

          <div
            class="iseq-report-variants-filtering__buttons iseq-report-variants-filtering__buttons--right"
          >
            <v-iseq-btn
              :disabled="!filterType || (!filterValue && filterType !== 'Is not empty' && filterType !== 'Is empty')"
              @click="addFilterRule()"
            >
              Save filter
            </v-iseq-btn>
          </div>
        </div>

        <div class="iseq-report-variants-filtering__separator" />

        <div class="iseq-report-variants-filtering__header">
          <span class="iseq-report-variants-filtering__header__title">
            {{ activeFilters.length ? 'Active column filters:' : 'No active column filters' }}
          </span>
        </div>

        <div class="iseq-report-variants-filtering__active-filters">
          <div
            v-for="filter in activeFilters"
            :key="filter.column + filter.rule + filter.value"
            class="iseq-report-variants-filtering__active-filters__element"
            @mouseenter="mouseOverRule = filter.column + filter.rule + filter.value"
            @mouseleave="mouseOverRule = ''"
          >
            <span :class="['iseq-report-variants-filtering__active-filters__element__text', (filter.value && (filter.column.icon || (filter.column.display && filter.column.display === 'icon'))) ? '' : 'overflow-handler']">
              <span class="name"> {{ `${filter.column.name ? filter.column.name : filter.column.text}: ` }} </span>

              <template v-if="filter.value && filter.column.display && filter.column.display === 'icon'">
                <span class="value">
                  {{ `${filter.rule}: ` }}
                  <span
                    v-for="item in filter.value"
                    :key="item"
                  >
                    <template v-if="filter.column.icons[item]">
                      <v-icon color="text">
                        {{ filter.column.icons[item] }}
                      </v-icon>
                    </template>

                    <template v-else>
                      {{ item }}
                    </template>
                  </span>
                </span>
              </template>

              <template v-else>
                <v-tooltip
                  top
                  :disabled="!widthExtended(`${filter.column.name}${filter.value}`)"
                >
                  <template #activator="{ on }">
                    <span
                      :ref="`${filter.column.name}${filter.value}`"
                      class="value overflow-handler"
                      v-on="on"
                    >
                      <template v-if="filter.value && filter.column.display && filter.column.display === 'pill'">
                        <span class="value">
                          {{ `${filter.rule}: ` }}
                          <span
                            v-for="item in filter.value"
                            :key="item"
                            class="pill pill--small"
                            :style="{'background-color': filter.column.colors[item] ? filter.column.colors[item] : 'black'}"
                          >
                            {{ filter.column.icons[item] ? filter.column.icons[item] : item }}
                          </span>
                        </span>
                      </template>

                      <template v-else>
                        {{ `${filter.rule}${filter.value ? ": " + filter.value : ""}` }}
                      </template>
                    </span>
                  </template>
                  
                  <template v-if="filter.value && filter.column.display && filter.column.display === 'pill'">
                    <span class="value">
                      {{ `${filter.rule}: ` }}
                      <span
                        v-for="item in filter.value"
                        :key="item"
                        class="pill pill--small"
                        :style="{'background-color': filter.column.colors[item] ? filter.column.colors[item] : 'black'}"
                      >
                        {{ filter.column.icons[item] ? filter.column.icons[item] : item }}
                      </span>
                    </span>
                  </template>

                  <template v-else>
                    {{ `${filter.rule}${filter.value ? ": " + filter.value : ""}` }}
                  </template>
                </v-tooltip>
              </template>
            </span>

            <div
              v-if="filter.column + filter.rule + filter.value === mouseOverRule"
              :class="{'iseq-report-variants-filtering__active-filters__element__icons':true, 'iseq-report-variants-filtering__active-filters__element__icons--icon-content-version': filter.column.display && filter.column.display === 'icon'}"
            >
              <v-icon
                color="primary"
                size="25px"
                class="icon"
                @click="editFilter(filter)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                color="red"
                size="25px"
                class="icon"
                @click="removeFilter(filter)"
              >
                mdi-filter-remove
              </v-icon>
            </div>
          </div>
        </div>

        <div class="iseq-report-variants-filtering__separator" />

        <div
          class="iseq-report-variants-filtering__buttons"
        >
          <v-iseq-btn
            type="secondary"
            @click="removeAllFilters()"
          >
            Clear all
          </v-iseq-btn>

          <v-iseq-btn
            @click="closeFilteringModal()"
          >
            Close
          </v-iseq-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: 'IseqReportVariantsFiltering',
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      visibility: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      headers: {
        type: Array,
        required: false,
        default: () => []
      },
      reclassifiedVariants: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data: function () {
      return {
        filterOptions: {
          string: ['Contains', 'Does not contain', 'Starts with', 'Ends with', 'Is exactly', 'Is not', 'Is not empty', 'Is empty'],
          number: ['Min', 'Max', 'Is exactly', 'Is not', 'Is not empty', 'Is empty'],
          boolean: ['Is exactly', 'Is not', 'Is not empty', 'Is empty'],
          select: ['Is one of', 'Is not one of', 'Is not empty', 'Is empty']
        },
        model: false,
        selectedHeaderFilter: undefined,
        filterType: undefined,
        filterValue: undefined,
        activeFilters: [],
        mouseOverRule: '',
        minerFilteringMode: 'all',
        minerHeaders: [
          {text: 'All variants', value: 'all'},
          {text: 'Not reclassified variants', value: 'notR'},
          {text: 'Variants reclassified previously (from REP)', value: 'reclassifiedP'},
          {text: 'Variants reclassified now', value: 'reclassifiedN'},
          {text: 'All reclassified variants', value: 'reclassifiedNP'}
        ]
      }
    },
    computed: {
      filteredItems() {
        let filteredNodes = this.items;
        switch (this.minerFilteringMode) {
        case 'all':
          break;
        case 'notR':
          filteredNodes = filteredNodes.filter(node => !node.manualClassification && !this.isNodeCurrentlyReclassified(node));
          break;
        case 'reclassifiedP':
          filteredNodes = filteredNodes.filter(node => !!node.manualClassification);
          break;
        case 'reclassifiedN':
          filteredNodes = filteredNodes.filter(node => this.isNodeCurrentlyReclassified(node));
          break;
        case 'reclassifiedNP':
          filteredNodes = filteredNodes.filter(node => this.isNodeCurrentlyReclassified(node) || !!node.manualClassification);
          break;
        default:
          break;
        }
        for (const filter of this.activeFilters) {
          if (filter.rule === 'Is empty') {
            filteredNodes = filteredNodes.filter(node => !node[filter.column.value] && node[filter.column.value] !== 0);
          }
          else if (filter.rule === 'Is not empty') {
            filteredNodes = filteredNodes.filter(node => !!node[filter.column.value] || node[filter.column.value] === 0);
          }
          else {
            filteredNodes = filteredNodes.filter(node => !!node[filter.column.value] || node[filter.column.value] === 0);
            switch (filter.type) {
            case 'string':
              switch (filter.rule) {
              case 'Contains':
                filteredNodes = filteredNodes.filter(node => String(node[filter.column.value]).toLowerCase().includes(filter.value.toLowerCase()));
                break;
              case 'Does not contain':
                filteredNodes = filteredNodes.filter(node => !String(node[filter.column.value]).toLowerCase().includes(filter.value.toLowerCase()));
                break;
              case 'Starts with':
                filteredNodes = filteredNodes.filter(node => String(node[filter.column.value]).toLowerCase().startsWith(filter.value.toLowerCase()));
                break;
              case 'Ends with':
                filteredNodes = filteredNodes.filter(node => String(node[filter.column.value]).toLowerCase().endsWith(filter.value.toLowerCase()));
                break;
              case 'Is exactly':
                filteredNodes = filteredNodes.filter(node => String(node[filter.column.value]).toLowerCase() === filter.value.toLowerCase());
                break;
              case 'Is not':
                filteredNodes = filteredNodes.filter(node => String(node[filter.column.value]).toLowerCase() !== filter.value.toLowerCase());
                break;
              default:
                break;
              }
              break;
            case 'number':
              switch (filter.rule) {
              case 'Min':
                filteredNodes = filteredNodes.filter(node => Number(node[filter.column.value]) >= filter.value);
                break;
              case 'Max':
                filteredNodes = filteredNodes.filter(node => Number(node[filter.column.value]) <= filter.value);
                break;
              case 'Is exactly':
                filteredNodes = filteredNodes.filter(node => Number(node[filter.column.value]) === filter.value);
                break;
              case 'Is not':
                filteredNodes = filteredNodes.filter(node => Number(node[filter.column.value]) !== filter.value);
                break;
              default:
                break;
              }
              break;
            case 'boolean':
              switch (filter.rule) {
              case 'Is exactly':
                filteredNodes = filteredNodes.filter(node => node[filter.column.value].toLowerCase() === filter.value);
                break;
              case 'Is not':
                filteredNodes = filteredNodes.filter(node => node[filter.column.value].toLowerCase() !== filter.value);
                break;
              }
              break;
            case 'select':
              switch (filter.rule) {
              case 'Is one of':
                filteredNodes = filteredNodes.filter(node => filter.value.includes(node[filter.column.value]));
                break;
              case 'Is not one of':
                filteredNodes = filteredNodes.filter(node => !filter.value.includes(node[filter.column.value]));
                break;
              }
              break
            default:
              break;
            }
          }
        }
        return filteredNodes;
      },
      filteredHeaderValid() {
        return this.selectedHeaderFilter ? this.headers.map(header => header.value).includes(this.selectedHeaderFilter.value) : false;
      }
    },
    watch: {
      selectedHeaderFilter(newValue) {
        this.filterType = undefined;
        this.filterValue = undefined;
      },
      filterType(newValue) {
        this.filterValue = undefined;
      },
      filteredItems: {
        handler(newValue) {
          this.$emit('updateFilteredValues', newValue);
        },
        deep: true,
      },
      activeFilters: {
        handler(newValue) {
          this.$emit('updateFiltersActive', !!newValue.length);
        },
        deep: true,
      },
      visibility: {
        handler(newValue) {
          this.model = newValue;
        },
      }
    },
    methods: {
      widthExtended(value) {
        if (this.$refs[value] && this.$refs[value][0]) {
          const element = this.$refs[value][0];
          let childrenWidth = 0;
          for (let childElement of element.parentElement.children) {
            childrenWidth += childElement.offsetWidth;
          }
          return element.parentElement.clientWidth < childrenWidth;
        } else {
          return false;
        }
      },
      selectFilterOptions(column) {
        const options = [];
        for (const item of this.items) {
          if (!options.includes(item[column]) && !!item[column]) {
            options.push(item[column]);
          }
        }
        return options;
      },
      isNodeCurrentlyReclassified(item) {
        const foundRec = this.reclassifiedVariants.find(f => f.gene === item.gene && f.variant === item.variant && f.tumorType === item.tumorType && f.tumorSubtype === item.tumorSubtype);
        return !!foundRec;
      },
      addFilterRule() {
        this.activeFilters.push({
          column: this.selectedHeaderFilter,
          rule: this.filterType,
          value: this.filterValue,
          type: this.selectedHeaderFilter.type ? this.selectedHeaderFilter.type : 'string'
        })
        this.selectedHeaderFilter = undefined;
      },
      async editFilter(filter) {
        this.selectedHeaderFilter = filter.column;
        setTimeout(() => {this.filterType = filter.rule}, 100)
        setTimeout(() => {this.filterValue = filter.value}, 100)
        this.activeFilters.splice(this.activeFilters.indexOf(filter), 1);
      },
      removeFilter(filter) {
        this.activeFilters.splice(this.activeFilters.indexOf(filter), 1);
      },
      removeAllFilters() {
        this.minerFilteringMode = 'all';
        this.activeFilters = [];
      },
      closeFilteringModal() {
        this.$emit('closed');
      }
    }
  }
</script>

<style scoped lang="scss">
.iseq-report-variants-filtering {
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 500;
    }
  }

  &__filter {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__field {
      margin-top: 10px;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;

    &--right {
      justify-content: flex-end;
    }
  }

  &__separator {
    background-color: var(--light);
    height: 2px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__active-filters {
    margin-top: -10px;
    margin-left: 15px;

    &__element{
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 5px 0;
      padding: 12px;
      border-radius: 5px;
      background: var(--light-2);
      font-size: 14px;
      border-width: 1px;
      border-style: solid;
      border-color: var(--light);
      white-space: nowrap;
      overflow: hidden;
      height: 48px;
      text-overflow: ellipsis;

      &__text {
        .name {
          color: var(--font-color);
          font-weight: bold;
        }

        .value {
          color: var(--font-inverted-color);
          margin-left: 10px;
        }
      }

      .overflow-handler {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__icons {
        &--icon-content-version{
          height: 100%;
          width: 90px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          background: rgb(0,0,0);
          background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(245,246,250,1) 20%);
          position: absolute;
          top: 0;
          right: 0;
          padding-left: 4px;
          padding-right: 12px;
          -webkit-box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
          -moz-box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
          box-shadow: -60px 0px 24px -30px rgba(66, 68, 90, 1);
        }

        .icon {
          width: min-content;
          margin-right: 4px;

          &--blue {
            color: var(--primary-color) !important;
          }

          &--red {
            color: var(--danger-color) !important;
          }
        }
      }
    }
  }
}

.pill {
  margin-right: 4px;
  width: min-content;
  display: block;
  padding: 3px 10px;
  color: white;
  border-radius: 15px;
  font-weight: bold;

  &--small {
    display: inline-block;
    padding: 0 8px;
  }
}
</style>