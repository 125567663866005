<template>
  <div class="iseq-variant-miner">
    <v-iseq-card
      class="iseq-variant-miner__content"
      title="Variant MINER context"
    >
      <div class="iseq-variant-miner__content__context-fields">
        <v-text-field
          readonly
          outlined
          hide-details
          background-color="#eee"
          label="Project"
          class="text-field"
          style="max-width: 33%"
          :value="project.name"
        />

        <v-select
          v-if="samplesMinimal.length"
          v-model="selectedSample"
          outlined
          hide-details
          class="iseq-select"
          return-object
          label="Sample"
          item-text="name"
          :items="samplesMinimal"
          placeholder="Select sample"
          style="margin-bottom: 15px; max-width: 33%"
          @change="selectSample"
        />

        <v-select
          v-model="selectedAnalysis"
          outlined
          hide-details
          class="iseq-select"
          label="Analysis"
          item-text="name"
          item-value="uuid"
          :disabled="!selectedSample || !sampleVariantAnalyses.length"
          :items="sampleVariantAnalyses"
          placeholder="Select variant analysis"
          style="margin-bottom: 15px; max-width: 33%"
          @change="selectAnalysis"
        />
      </div>

      <div
        class="separator"
        style="margin-bottom: 10px"
      />

      <div v-if="!!analysis.uuid">
        <div class="iseq-variant-miner">
          <div v-show="!selectHeaders">
            <div class="iseq-variant-miner__content">
              <div class="iseq-variant-miner__content__tools">
                <div class="iseq-variant-miner__content__tools__column">
                  <div class="iseq-variant-miner__content__tools__title">
                    Data filtering
                  </div>

                  <div class="iseq-variant-miner__content__tools__searchbar">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-text-field
                            v-model="search"
                            label="Search"
                            outlined
                            hide-details
                          />
                        </div>
                      </template>
                      Searches through all visible data without affecting filters
                    </v-tooltip>
                  </div>

                  <div class="iseq-variant-miner__content__tools__button-row">
                    <v-iseq-btn
                      :type="filterRulesActive ? 'primary' : 'secondary'"
                      class="iseq-button"
                      @click="filterDataModal = true"
                    >
                      {{ `Filter data: ${filterRulesActive ? 'ON' : 'OFF'}` }}
                    </v-iseq-btn>

                    <v-iseq-btn
                      ml
                      class="iseq-button"
                      @click="selectHeaders=true"
                    >
                      Columns' settings
                    </v-iseq-btn>
                  </div>

                  <div
                    v-show="filterRulesActive"
                    class="iseq-variant-miner__content__tools__button-row"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-iseq-btn
                            type="primary"
                            class="iseq-button"
                            :disabled="deselectUnmatchedActive"
                            @click="deselectUnmatched()"
                          >
                            Deselect unmatched
                          </v-iseq-btn>
                        </div>
                      </template>
                      Deselects previously selected values that don't match currently set filters<br>
                      You have {{ deselectUnmatchedActive ? 'no' : unmatchedVariantNumber }} unmatched variant{{ unmatchedVariantNumber === 1 ? '' : 's' }}
                    </v-tooltip>

                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-iseq-btn
                            ml
                            class="iseq-button"
                            @click="handleAllFiltered"
                          >
                            {{ areAllFilteredSelected ? 'Deselect' : 'Select' }} all filtered
                          </v-iseq-btn>
                        </div>
                      </template>
                      Selects all filtered items if none or only some are selected. Deselects all filtered items if all are already selected.
                    </v-tooltip>
                  </div>
                </div>

                <div class="iseq-variant-miner__content__tools__separator" />

                <div class="iseq-variant-miner__content__tools__column">
                  <template v-if="!!analysis.variantSelectors[0].wdlId">
                    <div class="iseq-variant-miner__content__tools__title">
                      Variants mining and analysis
                    </div>

                    <div class="iseq-variant-miner__content__tools__button-row">
                      <v-iseq-btn
                        class="iseq-button iseq-button--wide"
                        @click="variantAnalysisModalVisibility = true"
                      >
                        Run variant analysis
                      </v-iseq-btn>
                    </div>

                    <div class="iseq-variant-miner__content__tools__button-row">
                      <v-iseq-btn
                        class="iseq-button iseq-button--wide"
                        :disabled="reclassifiedVariants.length === 0"
                        @click="saveMiner()"
                      >
                        Save variant reclassifications
                      </v-iseq-btn>
                    </div>

                    <div class="iseq-variant-miner__content__tools__button-row">
                      <v-iseq-btn
                        width="310px"
                        class="iseq-button iseq-button--wide"
                        type="secondary"
                        @click="downloadToFile()"
                      >
                        Download selected to CSV file
                      </v-iseq-btn>
                    </div>
                  </template>
                </div>
              </div>
              
              <div class="iseq-variant-miner__content__table">
                <iseq-loading-spinner
                  v-if="showLoader"
                  text="Loading variants..."
                />
                <v-data-table
                  v-else
                  v-model="selected"
                  :headers="visibleHeaders"
                  :items="filteredItems"
                  show-select
                  select-strategy="all"
                  checkbox-color="primary"
                  item-key="variant"
                  :search="search"
                  height="100%"
                  fixed-header
                  @toggle-select-all="selectAllValues"
                  @click:row="selectValue"
                >
                  <template
                    v-for="header in visibleHeaders"
                    #[`header.${header.value}`]="{}"
                  >
                    {{ header.name ? header.name : header.text }}
                    <v-tooltip
                      :key="header.value"
                      top
                    >
                      <template #activator="{ on }">
                        <v-icon
                          color="primary"
                          size="15px"
                          class="mb-5"
                          v-on="on"
                        >
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>{{ header.description }}</span>
                    </v-tooltip>
                  </template>

                  <template
                    v-for="header in visibleHeaders"
                    #[`item.${header.value}`]="{item}"
                  >
                    <template v-if="header.text === 'Actions'">
                      <v-tooltip
                        :key="header.value"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            :key="header.value"
                            color="text"
                            v-on="on"
                            @click.stop="() => reclassifyVariant(item)"
                          >
                            mdi-account-edit-outline
                          </v-icon>
                        </template>
                        Reclassify variant
                      </v-tooltip>

                      <v-tooltip
                        v-if="findVariantIndexInArray(reclassifiedVariants, item) !== null"
                        :key="header.value"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            :key="header.value"
                            color="red"
                            class="ml-2"
                            v-on="on"
                            @click.stop="() => removeVariantReclassification(item)"
                          >
                            mdi-close-circle-outline
                          </v-icon>
                        </template>
                        Remove current variant reclassification
                      </v-tooltip>
                    </template>

                    <template v-else-if="header.value === 'primaryClassification'">
                      {{ classificationsNames[item[header.value]] }}
                    </template>

                    <template v-else-if="header.value === 'manualClassification'">
                      {{ classificationsNames[displayValueForVariantManualClassification(item)] }}
                    </template>

                    <template v-else-if="header.value === 'comment'">
                      {{ displayCommentForVariantManualClassification(item) }}
                    </template>

                    <template v-else-if="header.type === 'outsideLink'">
                      <span
                        v-if="item[header.value] && item[header.value].link"
                        :key="header.value"
                        class="link"
                        @click="openOutsideLink(item[header.value])"
                      >{{ item[header.value].text }}  {{ item[header.value] }}</span>

                      <span
                        v-else-if="!item[header.value].link && item[header.value].text"
                        :key="header.value"
                      >
                        {{ item[header.value].text }}
                      </span>
                    </template>

                    <template v-else-if="header.type === 'link'">
                      <span
                        v-if="item[header.value]"
                        :key="header.value"
                        class="link"
                        @click="getReportFile(item[header.value])"
                      >Click to view</span>
                    </template>

                    <template v-else-if="header.display && header.display === 'icon'">
                      <template v-if="header.icons[item[header.value]]">
                        <v-icon
                          :key="header.value"
                          color="text"
                        >
                          {{ header.icons[item[header.value]] }}
                        </v-icon>
                      </template>

                      <template v-else>
                        {{ item[header.value] }}
                      </template>
                    </template>

                    <template v-else-if="String(item[header.value]).length > 25">
                      <v-tooltip
                        :key="header.value"
                        top
                      >
                        <template #activator="{ on }">
                          <span v-on="on">
                            {{ `${String(item[header.value]).slice(0, 25)}...` }}
                          </span>
                        </template>
                        {{ item[header.value] }}
                      </v-tooltip>
                    </template>

                    <template v-else>
                      <span
                        :key="header.value"
                        :class="{'pill': (header.display && header.display === 'pill')}"
                        :style="{'background-color': (header.display && header.display === 'pill') ? (header.colors[item[header.value]] ? header.colors[item[header.value]] : 'black') : 'transparent' }"
                      >
                        <template v-if="header.display && header.display === 'pill'">
                          {{ header.icons[item[header.value]] ? header.icons[item[header.value]] : item[header.value] }}
                        </template>

                        <template v-else>
                          {{ item[header.value] }}
                        </template>
                      </span>
                    </template>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

          <div v-show="!!selectHeaders">
            <iseq-report-variants-columns
              :parent-selected-headers="selectedHeaders"
              :all-headers="headers"
              :previous-analysis-name="analysis.name"
              @closed="selectHeaders = false"
              @updateSelectedHeaders="updateSelectedHeaders"
            />
          </div>
        </div>

        <iseq-report-variants-filtering
          :visibility="filterDataModal"
          :items="items"
          :headers="visibleHeaders.slice(1)"
          :reclassified-variants="reclassifiedVariants"
          @closed="filterDataModal = false"
          @updateFilteredValues="updateFilteredValues"
          @updateFiltersActive="updateFiltersActive"
        />

        <iseq-variant-reclassification-modal
          v-if="reclassificationModalVisibility"
          :type="repType"
          :variant="variantToReclassify"
          @actionRejected="reclassificationModalVisibility = false"
          @actionConfirmed="confirmReclassifyVariant"
        />
        
        <iseq-variant-analysis-modal 
          v-if="variantAnalysisModalVisibility"
          :is-reclassification-present="reclassifiedVariants.length > 0"
          @closed="variantAnalysisModalVisibility = false"
          @runVariantReport="confirmReportVariants"
          @runVariantReportAndSaveMiner="runVariantReportAndSaveMiner"
        />

        <iseq-simple-message-modal
          v-if="fileBlobDialog"
          :header="file.name"
          button-text="Close"
          width="calc(100vw - 170px)"
          @closed="fileBlobDialog = false"
        >
          <div style="text-align: center;">
            <iframe
              :src="file.url"
              style="width: 98%; height: calc(100vh - 251px);"
            />
          </div>
        </iseq-simple-message-modal>
      </div>
    </v-iseq-card>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqReportVariantsFiltering from "@/views/Analysis/components/IseqReportVariantsFiltering.modal.vue";
  import IseqReportVariantsColumns from "@/views/Analysis/components/IseqReportVariantsColumns.component.vue";
  import IseqSimpleMessageModal from "@/components/modals/IseqSimpleMessageModal.component.vue";
  import IseqLoadingSpinner from "@/components/ui/LoadingSpinner.vue";
  import IseqCard from "@/components/ui/IseqCard.vue";
  import IseqVariantReclassificationModal from "@/components/modals/IseqVariantReclassificationModal.vue";
  import IseqVariantAnalysisModal from "@/components/modals/IseqVariantAnalysis.modal.vue";

  export default {
    name: "VariantMiner",
    components: {
      IseqVariantAnalysisModal,
      IseqVariantReclassificationModal,
      IseqLoadingSpinner,
      IseqSimpleMessageModal,
      IseqReportVariantsColumns,
      IseqReportVariantsFiltering,
      "v-iseq-btn": IseqButton,
      "v-iseq-card": IseqCard
    },
    data: function () {
      return {
        active: true,
        items: [],
        selected: [],
        headers: [],
        selectHeaders: false,
        selectedHeaders: [],
        search: "",
        analysisName: "",
        analysisDescription: undefined,
        analysisNameRules: [
          v => (!!v ? v.length <= 50 : true) || "Name cannot be longer than 50 characters",
          v => !this.sample.analysesNames.includes(v) || "Analysis named " + v + " already exists in this organization"
        ],
        dataFormValidity: false,
        filterDataModal: false,
        filteredItems: [],
        filterRulesActive: false,
        fileBlobDialog: false,
        variantToReclassify: {},
        reclassificationModalVisibility: false,
        file: {},
        showLoader: false,
        selectedSample: undefined,
        selectedAnalysis: undefined,
        reclassifiedVariants: [],
        variantsSelectedForReclassificationRemoval: [],
        repType: "",
        variantAnalysisModalVisibility: false,
        classificationsNames: {
          BENIGN: "Benign",
          LIKELY_BENIGN: "Likely Benign",
          VUS: "VUS",
          LIKELY_PATHOGENIC: "Likely Pathogenic",
          PATHOGENIC: "Pathogenic",
          TIER_IA: "Tier Ia",
          TIER_IB: "Tier Ib",
          TIER_IIC: "Tier IIc",
          TIER_IID: "Tier IId",
          TIER_III: "Tier III",
          TIER_IV: "Tier IV"
        }
      }
    },
    watch: {
      analysis(newAnalysis) {
        if (!!newAnalysis.uuid) {
          this.getFile();
        }
      },
    },
    // eslint-disable-next-line vue/order-in-components
    computed: {
      ...mapState('analysis', ['analysis', 'variantsData', 'analysisFiles']),
      ...mapState('sample', ['sample', 'samplesMinimal', 'sampleVariantAnalyses']),
      ...mapState('project', ['project']),

      visibleHeaders() {
        let headers = this.headers.filter(header => this.selectedHeaders.includes(header.value) || header.required);
        headers.unshift({text: "Actions", description: "Variant MINER actions", sortable: false});
        return headers;
      },
      deselectUnmatchedActive() {
        if (!this.filterRulesActive) {
          return true;
        } else {
          return !this.selected.some(item => !this.filteredItems.includes(item));
        }
      },
      unmatchedVariantNumber() {
        return this.selected.filter(item => !this.filteredItems.includes(item)).length;
      },
      areAllFilteredSelected() {
        return !this.filteredItems.some(item => !this.selected.includes(item));
      }
    },
    created() {
      this.$store.commit("analysis/cleanAllData");
      this.$store.dispatch("sample/getSampleListMinimal");
    },
    beforeDestroy() {
      this.$store.commit('analysis/setVariantsData', {variants: [], columns: {}});
    },
    methods: {
      selectSample(payload) {
        this.$store.dispatch('sample/getSampleVariantAnalyses', {uuid: payload.uuid});
      },
      selectAnalysis(payload) {
        this.$store.dispatch('analysis/getAnalysis', payload)
      },
      updateSelectedHeaders(payload) {
        this.selectedHeaders = payload;
      },
      findVariantIndexInArray(variantArray, variant) {
        const foundVariant = variantArray.find(v =>
          v.gene === variant.gene &&
          v.variant === variant.variant &&
          v.tumorType === variant.tumorType &&
          v.tumorSubtype === variant.tumorSubtype
        );
        return foundVariant ? variantArray.indexOf(foundVariant) : null;
      },
      removeVariantReclassification(item) {
        const variantToRemoveIndex = this.findVariantIndexInArray(this.reclassifiedVariants, item)
        if (variantToRemoveIndex !== null) {
          this.reclassifiedVariants.splice(variantToRemoveIndex, 1);
        } else {
          this.variantsSelectedForReclassificationRemoval.push(item);
        }
      },
      confirmReportVariants(data) {
        this.variantAnalysisModalVisibility = false;

        let selVariants = this.selected.map(v => {
          return {
            gene: v.gene,
            variant: v.variant,
            tumorType: v.tumorType,
            tumorSubtype: v.tumorSubtype,
            primaryClassification: v.primaryClassification,
            manualClassification: this.displayValueForVariantManualClassification(v),
            comment: this.displayCommentForVariantManualClassification(v)
          };
        });

        let inputs = {};
        inputs[this.analysis.variantSelectors[0].variants[0].inputName] = JSON.stringify(selVariants);
        this.$store.dispatch('analysis/runVariantsAnalysis', {
          data: {
            inputs: JSON.stringify(inputs),
            workflowId: this.analysis.variantSelectors[0].wdlId,
            name: data.analysisName.toString(),
            description: data.analysisDescription ? this.analysisDescription : ""
          },
          analysisUuid: this.analysis.uuid,
          sampleUuid: this.analysis.sample.uuid
        });
      },
      runVariantReportAndSaveMiner(data) {
        this.saveMiner();
        this.confirmReportVariants(data);
      },
      saveMiner() {
        this.$store.dispatch("project/reclassifyVariants", {
          uuid: this.project.uuid,
          data: {
            variants: this.reclassifiedVariants,
            type: this.repTypeForClassification(this.reclassifiedVariants[0].primaryClassification)
          },
          reroute: false
        });
      },
      repTypeForClassification(classification) {
        if (["TIER_IA", "TIER_IB", "TIER_IIC", "TIER_IID", "TIER_III", "TIER_IV"].includes(classification)) {
          return "SOMATIC";
        } else {
          return "GERMLINE";
        }
      },
      reclassifyVariant(item) {
        this.variantToReclassify = item;

        const reclassificationIndex = this.findVariantIndexInArray(this.reclassifiedVariants, item);
        if (reclassificationIndex !== null) {
          const reclassificationData = this.reclassifiedVariants[reclassificationIndex];
          this.variantToReclassify.manualClassification = reclassificationData.manualClassification;
          this.variantToReclassify.comment = reclassificationData.comment;
        }

        this.repType = this.repTypeForClassification(item.primaryClassification);
        this.reclassificationModalVisibility = true;
      },
      displayValueForVariantManualClassification(item) {
        const foundRec = this.reclassifiedVariants.find(f => f.gene === item.gene && f.variant === item.variant && f.tumorType === item.tumorType && f.tumorSubtype === item.tumorSubtype);
        if (foundRec) {
          return foundRec.manualClassification;
        } else {
          return item.manualClassification;
        }
      },
      displayCommentForVariantManualClassification(item) {
        const foundRec = this.reclassifiedVariants.find(f => f.gene === item.gene && f.variant === item.variant && f.tumorType === item.tumorType && f.tumorSubtype === item.tumorSubtype);
        if (foundRec) {
          return foundRec.comment;
        } else {
          return item.comment;
        }
      },
      selectValue(item) {
        if (this.selected.includes(item)) {
          this.selected.splice(this.selected.indexOf(item), 1);
        } else {
          this.selected.push(item);
        }
      },
      selectAllValues(event) {
        if (event.value === true) {
          this.selected = [...new Map([...this.selected, ...this.filteredItems].map(obj => [JSON.stringify(obj), obj])).values()];
        } else {
          this.selected = this.selected.filter(obj1 => !this.filteredItems.some(obj2 => JSON.stringify(obj1) === JSON.stringify(obj2)));
        }
      },
      deselectUnmatched() {
        this.$nextTick(() => {
          this.selected = this.selected.filter(item => this.filteredItems.includes(item));
        });
        this.$forceUpdate();
      },
      handleAllFiltered() {
        if (this.filteredItems.every(item => this.selected.includes(item))) {
          this.deselectAllFiltered();
        } else {
          this.selectAllFiltered()
        }
      },
      selectAllFiltered() {
        this.selected = this.selected.filter(item => !this.filteredItems.includes(item));
        this.selected = [...this.selected, ...this.filteredItems];
      },
      deselectAllFiltered() {
        this.selected = this.selected.filter(item => !this.filteredItems.includes(item));
      },
      updateFilteredValues(values) {
        this.filteredItems = values;
      },
      updateFiltersActive(value) {
        this.filterRulesActive = value;
      },
      confirmReclassifyVariant(item) {
        const variantToRemoveIndex = this.findVariantIndexInArray(this.reclassifiedVariants, item)
        if (variantToRemoveIndex !== null) {
          this.reclassifiedVariants.splice(variantToRemoveIndex, 1);
        }

        this.reclassificationModalVisibility = false;
        this.reclassifiedVariants.push(item)

        const variantIndex = this.findVariantIndexInArray(this.variantsSelectedForReclassificationRemoval, item);
        if (variantIndex !== null) {
          this.variantsSelectedForReclassificationRemoval.splice(variantIndex, 1);
        }
      },
      downloadToFile() {
        const headers = this.headers.map(header => header.value).join(',') + '\r\n';
        const content = this.selected.map(selectedNode => {
          const line = [];
          for (const header of this.headers) {
            line.push(`"${selectedNode[header.value]}"`);
          }
          return line.join(',');
        }).join('\r\n');

        this.download(`${this.analysis.name.replace(' ', '_')}_viariants.csv`, headers+content)
      },
      download(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
      getFile() {
        const outputAddress = this.analysis.variantSelectors[0].variants[0].outputAddress;
        const fileAddress = outputAddress.slice(outputAddress.split('/', 2).join('/').length + 1);
        this.showLoader = true;
        this.$store.dispatch('sample/downloadFile', {
          type: "analysis",
          key: fileAddress,
          analysisUuid: this.analysis.uuid
        }).then(response => {
          axios({
            url: response.url,
            responseType: 'json',
            credentials: "include",
            mode: "no-cors",
            headers: {
              "Accept": "application/json; odata=verbose"
            }
          }).then((response) => {
            this.items = response.data.variants;
            this.filteredItems = response.data.variants;
            for (const [key, value] of Object.entries(response.data.columns)) {
              this.headers.push({text: key, value: key, ...value});
            }
            this.selectedHeaders = this.headers.filter(node => node.default).map(node => node.text);
            this.showLoader = false;
          })
        })
      },
      getReportFile(path) {
        this.$store.dispatch('sample/downloadFile', {
          type: 'analysis',
          key: `${this.analysis.uuid}/${path.slice(0, path.indexOf('#'))}`,
          analysisUuid: this.analysis.uuid,
          sampleUuid: this.analysis.sample.uuid
        }).then(response => {
          this.file.name = 'Variant';
          this.file.url = `${response.url}${path.slice(path.indexOf('#'))}`;
          this.fileBlobDialog = true;
        })
      },
      openOutsideLink(item) {
        window.open(item.link, '_blank').focus();
      }
    }
  }
</script>



<style scoped lang="scss">
.iseq-variant-miner {
  &__content {
    &__context-fields{
      display: flex;
      flex-direction: row;

      .iseq-select {
        margin-left: 10px;
      }
    }

    &__field {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 700;
    }

    &__topbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;
      margin-bottom: -20px;

      &__search {
        width: 310px;
        margin-bottom: 10px;
      }
    }
  }

  &__content {

    &__table {
      width: 100%;
      border-right: 1px solid var(--light);
      margin-top: 20px;

      .pill {
        width: min-content;
        display: block;
        padding: 3px 10px;
        color: white;
        border-radius: 15px;
        font-weight: bold;
      }
    }

    &__tools {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(50% - 50px);
      }

      &__title {
        color: var(--dark);
        font-size: 1.7em;
        font-weight: 700;
      }

      &__subtitle {
        color: var(--dark);
        font-size: 1.4em;
        font-weight: 700;
        margin: 15px 0 -15px 0;
      }

      &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
      }

      &__searchbar {
        width: 100%;
        margin-top: 10px;
      }

      &__separator {
        width: 2px;
        min-height: 100%;
        background-color: var(--light);
      }

      &__analysis-data {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;

        &__name {
          width: 100%;
        }

        &__description {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }

  &__close-button {
    margin-top: -45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &__button {
      max-width: 200px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep .v-data-table .v-data-footer {
    margin-top: 0px !important;
  }

  ::v-deep tbody tr {
    cursor: default;
  }

  .iseq-button {
    width: 200px;

    &--wide {
      width: 100%;
    }
  }

  .link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
  }
}

.separator {
  width: 100%;
  height: 2px;
  background-color: var(--light);
}
</style>