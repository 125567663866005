<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :max-width="isReclassificationPresent ? '600px' : '400px'"
  >
    <div class="iseq-variant-analysis-modal">
      <div class="iseq-variant-analysis-modal__header">
        <span class="iseq-variant-analysis-modal__header__title">
          Run variant analysis
        </span>
      </div>

      <div class="iseq-variant-analysis-modal__analysis-data">
        <v-form
          ref="variantAnalysisNameForm"
          v-model="isAnalysisFormValid"
        >
          <v-text-field
            v-model="analysisName"
            outlined
            :hide-details="!nameRulesBroken"
            :rules="analysisNameRules"
            label="Variant analysis name"
            class="iseq-variant-analysis-modal__analysis-data__name"
          />
        </v-form>

        <v-textarea
          v-model="analysisDescription"
          outlined
          hide-details
          rows="3"
          label="Variant analysis description"
          class="iseq-variant-analysis-modal__analysis-data__description"
        />
      </div>

      <div class="iseq-variant-analysis-modal__button-row">
        <v-iseq-btn
          class="iseq-button"
          type="secondary"
          width="100px"
          @click="close()"
        >
          Close
        </v-iseq-btn>

        <v-iseq-btn
          v-show="isReclassificationPresent"
          :disabled="!isAnalysisFormValid"
          class="iseq-button iseq-button--wide"
          @click="runVariantReportAndSaveMiner()"
        >
          Save variant reclassifications and Run analysis
        </v-iseq-btn>

        <v-iseq-btn
          :disabled="!isAnalysisFormValid"
          class="iseq-button iseq-button--wide"
          width="100px"
          @click="confirmReportVariants()"
        >
          Run analysis
        </v-iseq-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";
  import {mapState} from "vuex";

  export default {
    name: "IseqVariantAnalysisModal",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      isReclassificationPresent: {
        type: Boolean,
        required: true,
      }
    },
    data: function () {
      return {
        isOpen: true,
        analysisName: "",
        analysisDescription: "",
        analysisNameRules: [
          v => v.length !== 0 || "Analysis name is required",
          v => (!!v ? v.length <= 50 : true) || "Name cannot be longer than 50 characters",
          v => !this.sample.analysesNames.includes(v) || "Analysis named " + v + " already exists in this sample"
        ],
        isAnalysisFormValid: false,
      }
    },
    computed: {
      ...mapState('sample', ['sample']),
    },
    methods: {
      nameRulesBroken() {
        return this.analysisNameRules.some(item => item(this.analysisName) !== true);
      },
      confirmReportVariants() {
        this.$emit('runVariantReport', {analysisName: this.analysisName, analysisDescription: this.analysisDescription});
      },
      runVariantReportAndSaveMiner() {
        //this.$emit('runVariantReportAndSaveMiner', {analysisName: this.analysisName, analysisDescription: this.analysisDescription})
      },
      close() {
        this.$emit('closed');
      }
    }
  }
</script>

<style lang="scss"
       scoped>
.iseq-variant-analysis-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: white;

  &__header {
    margin-bottom: 20px;

    &__title {
      color: var(--dark);
      font-size: 1.7em;
      font-weight: 500;
    }
  }

  &__analysis-data {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    color: var(--dark);
    font-size: 1.4em;
  }

  &__button-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .iseq-btn {
      margin: 4px 0;
    }
  }
}
</style>